<template>
    <div class="transfer">
        <img src="@/assets/yq/transfer-loading.gif" />
    </div>
</template>

<script>
  import { getToken,removeCheckPlatform,removeToken } from "@/utils/cache"
  import { removeAllUserInfo,getAuthInfo,setAuthInfo } from "@/utils/auth"
  import { getCheckStatus } from "@/api/user";
  export default {
        created(){
            if(getToken()){
                this.$store.dispatch("app/setLoading",false);
                let j = 0;
                //每次请求一次，总共4次，成功就提前跳转
                let timer = setInterval(() => {
                    getCheckStatus().then((res) => {
                        let { status, data } = res;
                        j++;
                        if (status) {
                            let authInfo = data;
                            setAuthInfo( JSON.stringify(data) );
                            if( authInfo.esignVideoCheckStatus ){
                                this.$store.dispatch("app/setLoading",true);
                                clearInterval(timer);
                                this.$router.replace({
                                    path: "/authRes",
                                })

                            }else{
                                if(j == 4){
                                    this.$store.dispatch("app/setLoading",true);
                                    clearInterval(timer);
                                    this.$router.replace({
                                        path: "/authError",
                                        query:{
                                            authType:2,
                                            errMessage:""
                                        }
                                    })
                                }
                            }
                        }
                        if(j == 4){
                            this.$store.dispatch("app/setLoading",true);
                            clearInterval(timer)
                        }
                    }).catch(err=>{
                        this.$store.dispatch("app/setLoading",true);
                        this.$router.replace({
                            path: "/authError",
                            query:{
                                authType:2,
                                authTip:"网络不佳，请切换网络"
                            }
                        })
                    })
                }, 1000);
                
            }else{
                this.$store.dispatch('user/loginOut');
                setTimeout(()=>{
                    this.$router.replace({
                        path:"/login"
                    })
                },3000)
            }
        }
  }
</script>

<style scoped>
.transfer img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 50px;
    height: 50px;
}
</style>
